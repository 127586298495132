import React from "react";

const About = () => {
  return (
    <section id="about" className="w-screen h-fit bg-background px-24 py-14">
      <h1 className="uppercase text-3xl w-fit font-extrabold tracking-wider">
        About <span className="text-secondaryTextColor">ReBanker</span>
      </h1>
      <h3 className="text-textGrey text-lg">Simplified Payments</h3>
      <div className="grid sm:grid-cols-2 grid-cols-1 gap-14 grid-rows-3 mt-3">
        <p className="text-center">
          Welcome to ReBanker where we are revolutionizing student spending in
          schools! With our cutting-edge product, we have streamlined the way
          students manage their expenses, providing a seamless, automated and
          secure experience in transacting money.
        </p>
        <div className="flex flex-col items-center justify-around">
          <i class="fa-solid fa-lock text-6xl"></i>
          <p className="uppercase tracking-wider">Secure</p>
        </div>
        <p className="text-center">
          ReBanker utilizes state-of-the-art technology to automate student
          spending, offering an all-inclusive platform that offers an intuitive
          interface where students can effortlessly track their expenses, set
          budgets, and make purchases with ease simplifies transactions, in turn
          promoting financial literacy.
        </p>
        <div className="flex flex-col items-center justify-around">
          <i class="fa-regular fa-clock text-6xl"></i>
          <p className="uppercase tracking-wider mt-5">Fast</p>
        </div>
        <p className="text-center">
          For parents and guardians, we offer including robust tracking
          features, spending limits, and real-time monitoring capabilities to
          effortlessly track their children's spending across multiple schools
          simultaneously, ensuring transparency and peace of mind.
        </p>
        <div className="flex flex-col items-center justify-around">
          <i class="fa-solid fa-hands-holding-child text-6xl"></i>
          <p className="uppercase tracking-wider mt-5">Convenient</p>
        </div>
      </div>
    </section>
  );
};

export default About;
