import React from "react";
import image1 from "../assets/image-1.png";

const Features = () => {
  return (
    <section className="w-screen h-fit bg-background px-24 py-14" id="features">
      <h1 className="uppercase sm:text-5xl w-fit mx-auto font-extrabold tracking-wider">
        A <span className="text-secondaryTextColor">convenient</span> way to pay
      </h1>
      <div className="h-full w-full grid sm:grid-cols-2 grid-cols-1 grid-rows-3 gap-0  sm:p-5">
        <div className="flex items-center justify-center animate-slideInLeft">
          <img src={image1} alt="app" className="w-auto h-full" />
        </div>
        <div className="flex flex-col justify-evenly animate-slideInRight">
          <h1 className="text-secondaryTextColor text-center sm:text-4xl">
            Simplified Payments
          </h1>
          <p className="text-textColor text-center col-span-2 sm:col-span-1">
            School shop vendors can use the application to improve their
            business by spotting redundancies, tracking inventory and plan for
            their finances to ensure optimum profits are met
          </p>
        </div>
        <div className="flex flex-col justify-evenly animate-slideInLeft">
          <h1 className="text-secondaryTextColor sm:text-4xl text-center">
            Simple Interface
          </h1>
          <p className="text-textColor text-center animate-slideInRight">
            The application was built for simplicity, allowing for students,
            parents and vendors to quickly make transactions and save time. We
            value your feedback on this, click the button below to get in touch
            with us
          </p>
        </div>
        <div className=" flex items-center justify-center animate-slideInLeft">
          <img
            src={image1}
            alt="app"
            className="w-auto h-full hidden md:block"
          />
        </div>
        <div className=" flex items-center justify-center animate-slideInLeft">
          <img
            src={image1}
            alt="app"
            className="w-auto h-full hidden md:block"
          />
        </div>
        <div className="flex flex-col justify-evenly animate-slideInRight">
          <h1 className="text-secondaryTextColor sm:text-4xl text-center ">
            Customer Centred
          </h1>
          <p className="text-textColor text-center">
            Finance management is much more than saving, it is planning for the
            future, tracking both inventory and spending, setting limits and our
            application helps you do that
          </p>
        </div>
      </div>
    </section>
  );
};

export default Features;
