import React from "react";
import image1 from "../assets/image-1.png";

const Contact = () => {
  return (
    <section
      id="contact"
      className="w-screen sm:h-screen h-fit bg-background px-24 py-14"
    >
      <h1 className="uppercase text-3xl w-full font-extrabold tracking-wider text-center">
        Let's hear from <span className="text-secondaryTextColor">you</span>
      </h1>
      <div className="grid sm:grid-rows-1 sm:grid-cols-3 grid-rows-3 gap-16 mt-20">
        <div className="flex flex-col items-center justify-around">
          <i class="fa-regular fa-envelope text-6xl"></i>
          <h2>Email</h2>
          <a href="mailto:rebankerlimited@gmail.com">
            rebankerlimited@gmail.com
          </a>
        </div>
        <div className="flex flex-col items-center justify-around">
          <i class="fa-solid fa-location-dot text-6xl"></i>
          <h2>Find</h2>
          <a href="https://maps.app.goo.gl/v1MM8ocbTvLScguQ8">
            Jamii Towers, Nairobi Kenya
          </a>
        </div>
        <div className="flex flex-col items-center justify-around">
          <i class="fa-regular fa-envelope text-6xl"></i>
          <h2>Call</h2>
          <a href="tel:+254706384599">+254 706 384 599</a>
        </div>
      </div>
      <div className="w-full mt-24">
        <h1 className="text-4xl font-bold w-fit text-center mx-auto">
          Download the{" "}
          <span className="block text-secondaryTextColor">application</span>
        </h1>
        <img
          src={image1}
          className="w-1/3 mx-auto -mt-20 hidden md:block"
          alt="app"
        />
        <button className="mt-20 sm:mt-0 bg-secondaryTextColor px-10 py-4 flex flex-row items-center justify-around rounded-3xl mx-auto shadow-secondaryTextColor shadow-2xl">
          <i class="fa-solid fa-circle-down mr-5 "></i>
          Download
        </button>
      </div>
    </section>
  );
};

export default Contact;
